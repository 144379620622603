@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }

  body {
    @apply bg-white text-gray-900;
    font-family: "Inter", sans-serif;
  }

  /* More specific selectors to override Bootstrap */
  a,
  a:hover,
  a:focus,
  a:active {
    text-decoration: none !important;
  }

  .nav-link,
  .nav-link:hover,
  .nav-link:focus {
    text-decoration: none !important;
  }
}

@layer components {
  .btn {
    @apply inline-flex items-center gap-2 px-5 py-2.5 rounded-xl font-medium 
           transition-all duration-300 text-sm relative;
  }

  .btn-primary {
    @apply bg-primary-600 text-white
           shadow-sm
           hover:bg-primary-700
           hover:shadow-md hover:-translate-y-0.5
           active:shadow-inner active:translate-y-0
           active:bg-primary-800;
  }

  .btn-outline {
    @apply bg-white/80 backdrop-blur-sm
           border border-gray-200
           text-gray-700
           hover:bg-primary-600 hover:text-white
           hover:border-transparent
           hover:shadow-md hover:-translate-y-0.5
           active:shadow-inner active:translate-y-0
           active:bg-primary-700;
  }

  .hover-bounce {
    @apply transition-transform;
    animation: none;
  }

  .btn:hover .hover-bounce {
    animation: letter-bounce 1.5s ease-in-out infinite;
  }
}

@keyframes letter-bounce {
  0%,
  15%,
  85%,
  100% {
    transform: translateY(0);
  }
  35% {
    transform: translateY(-3px);
  }
  65% {
    transform: translateY(1.5px);
  }
}

.bg-grid-pattern {
  background-image: linear-gradient(to right, #e5e7eb 1px, transparent 1px),
    linear-gradient(to bottom, #e5e7eb 1px, transparent 1px);
  background-size: 4rem 4rem;
}

/* Custom Fonts */
.font-montserrat {
  font-family: "Montserrat", sans-serif;
}

:root {
  --primary-50: #eff6ff;
  --primary-100: #dbeafe;
  --primary-200: #bfdbfe;
  --primary-300: #93c5fd;
  --primary-400: #60a5fa;
  --primary-500: #3b82f6;
  --primary-600: #2563eb;
  --primary-700: #1d4ed8;
  --primary-800: #1e40af;
  --primary-900: #1e3a8a;
}
